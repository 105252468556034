import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import About from '../Components/About'
import Resume from '../Components/Resume'
// import Contact from '../Components/Contact'
//import Testimonials from '../Components/Testimonials'
import Portfolio from '../Components/Portfolio'
import $ from 'jquery';
import ReactGA from 'react-ga';

class Home extends React.Component
{
  constructor(props){
    super(props);
    this.state = {
      foo: 'bar',
      resumeData: {}
    };

    ReactGA.initialize(process.env.REACT_APP_GOOGLE);
    ReactGA.pageview(window.location.pathname);
  }

  getResumeData(){
    $.ajax({
      url:'/resumeData.json',
      dataType:'json',
      cache: false,
      success: function(data){
        this.setState({resumeData: data});
      }.bind(this),
      error: function(xhr, status, err){
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount(){
    this.getResumeData();
  }

  render() {
    const { project } = this.props.match.params
    return (
      <div className="App">
        <Header data={this.state.resumeData.main}/>
        <About data={this.state.resumeData.main}/>
        <Resume data={this.state.resumeData.resume}/>
        <Portfolio data={this.state.resumeData.portfolio} project={project} />
        {/* <Testimonials data={this.state.resumeData.testimonials}/> */}
        {/* <Contact data={this.state.resumeData.main}/> */}
        <Footer data={this.state.resumeData.main}/>
      </div>
    );
  }
}

export default Home
