import React, { Component } from 'react';
import Home from './Pages/Home'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"

import './App.css';

class App extends Component {

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/portfolio/:project" component={Home}/>
          <Route path="*" component={Home}/>
        </Switch>
      </Router>
    );
  }
}

export default App;
