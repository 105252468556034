import React, { Component, PureComponent  } from 'react';
import ReactMarkdown from 'react-markdown'
import PropTypes from "prop-types";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
//import js from 'react-syntax-highlighter/dist/esm/languages/prism/javascript';
//import C from 'react-syntax-highlighter/dist/esm/languages/prism/c';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { Link, Redirect } from 'react-router-dom';


//SyntaxHighlighter.registerLanguage('javascript', js);
//SyntaxHighlighter.registerLanguage('C', C);

class CodeBlock extends PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    language: PropTypes.string
  };

  static defaultProps = {
    language: null
  };

  render() {
    const { language, value } = this.props;
    return (
      <SyntaxHighlighter language={language} style={github} >
        {value}
      </SyntaxHighlighter>
    );
  }
}

/*
{
  "title":"Gatorloop - Tinypod",
  "summary":"",
  "details": "",
  "image":"tiny-pod.jpg",
  "github":"https://github.com/GatorloopSoftwareTeam/tiny-pod"
},
*/

class Project extends Component {
  state = {
    slideHidden: true,
    project: {
      title: "",
      summary: "",
      image: "",
      github: ""
    },
    markdown: "",
    redirectHome: false
  }


  getmarkdown = (filename) => {
    const readmePath = require(`../Projects/${filename}`);
    return fetch(readmePath)
    .then(response => {
      return response.text()
    })
  }

  componentDidMount() {
    let project = this.props.project

    if (project.markdown) {
      this.getmarkdown(project.markdown)
      .then((response) => {
        this.setState({ markdown: response })
      })
    }

    this.setState({
      project: project
    })

  }

  render() {
    let portfolio = window.document.getElementsByTagName("body")
    if (portfolio.length > 0) {
      portfolio = window.document.getElementsByTagName("body")[0]
      if (!this.state.slideHidden) {
        if (!portfolio.classList.contains("hide-scroll")) {
          portfolio.classList.add("hide-scroll")
        }
      }
  
      else {
        if (portfolio.classList.contains("hide-scroll")) {
          portfolio.classList.remove("hide-scroll")
        }
      }
    }

    if (this.state.redirectHome) {
      this.setState({ redirectHome: false })
      return <Redirect to="/" />
    }

    let project = this.state.project
    var projectImage = '/images/portfolio/'+project.image;
    return <div key={project.title} className="columns portfolio-item">
           <div className="item-wrap">
            <Link to={`/portfolio/${project.title}`} onClick={() => this.setState({ slideHidden: false })} title={project.title}>
               <img alt={project.title} src={projectImage} />
               <div className="overlay">
                  <div className="portfolio-item-meta">
                    <h5>{project.title}</h5>
                  </div>
                </div>
              <div className="link-icon">
                <p>Click to see project!</p>
              </div>
            </Link>
          </div>
            <SlidingPane
              className="sliding-panel-content"
              overlayClassName="sliding-panel-portfolio"
              title={project.title}
              isOpen={this.props.active}
              width="95%"
              onRequestClose={() => { 
                this.setState({ redirectHome: true })
                }}>
              <div style={{ backgroundColor: '#fff', marginLeft: '5%', marginRight: '5%', padding: '30px', color: 'black'}}>

              <ul style={{ 'fontSize': '40px', 'float': 'right', listStyle: 'none'}}>
                {project.github ?
                  
                  
                      <li>
                        <a href={project.github} target='_blank'>
                          <i class="fa fa-github" style={{ color: 'black'}} />
                        </a>
                      </li>
                  :
                  ''
                }

                {project.link ?
                <li>
                  <a href={project.link} target='_blank'>
                    <i class="fa fa-link" style={{ color: 'grey'}} />
                  </a>
                </li>
              :
              ''
              }
              </ul>

              
              <ReactMarkdown
              renderers={{ code: CodeBlock }}
              allowDangerousHtml>{this.state.markdown}</ReactMarkdown>

                {/* <h3>Summary</h3>
                <p>{project.summary}</p>

                <p>{project.details}</p> */}
              </div>

            </SlidingPane>
        </div>
  }
}


class Portfolio extends Component {
  render() {
    if(this.props.data){
      var project = this.props.data.projects.map((project) => {
        return <Project project={project} active={project.title.toLowerCase() === this.props.project?.toLowerCase()} />
      })
    }

    return (
      <section id="portfolio">

      <div className="row">

         <div className="twelve columns collapsed">

            <h1>Check Out Some of My Works.</h1>
            <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
                {project}
            </div>
          </div>
      </div>
   </section>
    );
  }
}

export default Portfolio;
